// BookingScheduleTable.tsx
import React, { useState, useMemo, useRef, useEffect } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useSwipeable } from "react-swipeable";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { BookingCell } from "./BookingCell";
import { Booking } from "../../../model/bookings/Booking";
import { Slot } from "../../../model/bookings/Slot";
import { FacilityScheduleForDay } from "../../../model/bookings/FacilityScheduleForDay";
import MakeBooking from "../MakeBooking";
import AvailableSlotCard from "../AvailableSlotCard";

interface SelectedBookingData {
  booking: Booking | null;
  facilityName: string;
  slot: Slot;
}

interface BookingScheduleTableProps {
  schedules: FacilityScheduleForDay[];
  onBookingSuccess: () => void;
  selectedDate: Date;
  initialFacilityIndex?: number; // Add this prop
  onFacilityChange?: (index: number) => void; // Add this to notify parent of changes
}

// Then update the BookingScheduleTable component:
export const BookingScheduleTable: React.FC<BookingScheduleTableProps> = ({
  schedules,
  onBookingSuccess,
  initialFacilityIndex = 0,
  onFacilityChange,
}) => {
  const [currentFacilityIndex, setCurrentFacilityIndex] =
    useState(initialFacilityIndex);

  // Add useEffect to sync with initialFacilityIndex
  useEffect(() => {
    setCurrentFacilityIndex(initialFacilityIndex);
  }, [initialFacilityIndex]);

  // Update handleFacilityChange to notify parent

  const [selectedBookingData, setSelectedBookingData] =
    useState<SelectedBookingData | null>(null);
  const [swipeAnimation, setSwipeAnimation] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (currentFacilityIndex < schedules.length - 1 && !isAnimating) {
        handleFacilityChange(currentFacilityIndex + 1, "left");
      }
    },
    onSwipedRight: () => {
      if (currentFacilityIndex > 0 && !isAnimating) {
        handleFacilityChange(currentFacilityIndex - 1, "right");
      }
    },
    onSwiping: (e) => {
      if (
        (currentFacilityIndex === 0 && e.deltaX > 0) ||
        (currentFacilityIndex === schedules.length - 1 && e.deltaX < 0)
      ) {
        return;
      }
      setSwipeAnimation(e.deltaX);
    },
    onSwiped: () => {
      setSwipeAnimation(0);
    },
    trackMouse: true,
    preventScrollOnSwipe: true,
    delta: 10,
    swipeDuration: 500,
    touchEventOptions: { passive: false }, // Prevent passive event warning
  });

  const timeSlots = useMemo(() => {
    const allSlots = new Set<number>();
    schedules.forEach((schedule) => {
      schedule.slots.forEach((slot) => {
        allSlots.add(slot.startTime.getTime());
      });
    });
    return Array.from(allSlots)
      .map((time) => new Date(time))
      .sort((a, b) => a.getTime() - b.getTime());
  }, [schedules]);

  const handleCellClick = (
    booking: Booking | null,
    slot: Slot,
    facilityName: string
  ) => {
    setSelectedBookingData({
      booking: booking || null,
      facilityName,
      slot,
    });
  };

  const handleCloseModals = (wasSuccessful: boolean = false) => {
    setSelectedBookingData(null);
    if (wasSuccessful) {
      onBookingSuccess();
    }
  };

  const handleFacilityChange = (
    newIndex: number,
    direction: "left" | "right"
  ) => {
    setIsAnimating(true);
    const startPosition = direction === "left" ? 100 : -100;
    setSwipeAnimation(startPosition);

    if (contentRef.current) {
      void contentRef.current.offsetHeight;
    }

    requestAnimationFrame(() => {
      setSwipeAnimation(0);
      setTimeout(() => {
        setCurrentFacilityIndex(newIndex);
        onFacilityChange?.(newIndex); // Notify parent of change
        setIsAnimating(false);
      }, 300);
    });
  };

  const getRowSpan = (booking: Booking, bookingIntervalMins: number) => {
    if (!booking) return 1;
    const durationInMinutes =
      (booking.bookingEndTimeUtc.getTime() -
        booking.bookingDateTimeUtc.getTime()) /
      (1000 * 60);
    return Math.ceil(durationInMinutes / bookingIntervalMins);
  };

  const getBookingAndSlotForCell = (
    facilitySchedule: FacilityScheduleForDay,
    time: Date
  ) => {
    const slot = facilitySchedule.slots.find(
      (s) => s.startTime.getTime() === time.getTime()
    );

    if (!slot) return null;

    const booking = facilitySchedule.bookings.find((b) => {
      const slotTime = time.getTime();
      const bookingStart = b.bookingDateTimeUtc.getTime();
      const bookingEnd = b.bookingEndTimeUtc.getTime();
      // Add check for non-cancelled bookings
      return (
        slotTime >= bookingStart &&
        slotTime < bookingEnd &&
        b.bookingStatus !== "Cancelled"
      );
    });

    if (booking && booking.bookingDateTimeUtc.getTime() !== time.getTime()) {
      return {
        slot,
        booking: null,
        facilityName: facilitySchedule.facilityName,
        skipRender: true,
      };
    }

    return {
      slot,
      booking: booking || null,
      facilityName: facilitySchedule.facilityName,
      skipRender: false,
    };
  };

  const formatTimeSlot = (date: Date): string => {
    return date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  };

  const createTimeSlotGrid = (schedules: FacilityScheduleForDay[]) => {
    const timeSlots = new Set<number>();

    // Collect ALL slots, both booked and available
    schedules.forEach((schedule) => {
      schedule.slots.forEach((slot) => {
        timeSlots.add(slot.startTime.getTime());
      });
    });

    const sortedTimes = Array.from(timeSlots)
      .map((time) => new Date(time))
      .sort((a, b) => a.getTime() - b.getTime());

    return sortedTimes.map((time) => ({
      time,
      facilities: schedules.map((schedule) => {
        // Find the slot for this time
        const slot = schedule.slots.find(
          (s) => s.startTime.getTime() === time.getTime()
        );

        // Find if there's a booking that covers this time
        const booking = schedule.bookings.find((b) => {
          if (b.bookingStatus === "Cancelled") return false;

          const slotTime = time.getTime();
          const bookingStart = b.bookingDateTimeUtc.getTime();
          const bookingEnd = b.bookingEndTimeUtc.getTime();

          return slotTime >= bookingStart && slotTime < bookingEnd;
        });

        // Important: Return the slot even if there's no booking
        return {
          facilityName: schedule.facilityName,
          slot: slot, // This will include available slots
          booking: booking || null,
          bookingIntervalMins: schedule.bookingIntervalMins,
        };
      }),
    }));
  };

  const DesktopView = () => {
    const grid = createTimeSlotGrid(schedules);
    const timeColumnWidth = "120px";
    const facilityColumnWidth = `calc((100% - ${timeColumnWidth}) / ${schedules.length})`;
    const cellHeight = "40px"; // Define consistent cell height

    return (
      <TableContainer component={Paper}>
        <Table size="small" sx={{ tableLayout: "fixed" }}>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  width: timeColumnWidth,
                  height: cellHeight,
                  padding: "8px",
                }}
              >
                Time
              </TableCell>
              {schedules.map((schedule) => (
                <TableCell
                  key={schedule.facilityName}
                  sx={{
                    width: facilityColumnWidth,
                    height: cellHeight,
                    padding: "8px",
                  }}
                >
                  {schedule.facilityName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {grid.map((row) => (
              <TableRow key={row.time.toISOString()}>
                <TableCell
                  sx={{
                    height: cellHeight,
                    padding: "8px",
                  }}
                >
                  {formatTimeSlot(row.time)}
                </TableCell>
                {row.facilities.map((facility, index) => {
                  // Calculate rowSpan for bookings
                  const rowSpan = facility.booking
                    ? Math.ceil(
                        (facility.booking.bookingEndTimeUtc.getTime() -
                          facility.booking.bookingDateTimeUtc.getTime()) /
                          (facility.bookingIntervalMins * 60 * 1000)
                      )
                    : 1;

                  // Only render if it's the first slot of a booking or not a booking
                  const shouldRender =
                    !facility.booking ||
                    facility.booking.bookingDateTimeUtc.getTime() ===
                      row.time.getTime();
                  if (!facility.slot) {
                    console.log("No slot found ");
                  }
                  if (facility.facilityName === "Squash 6") {
                    console.log(
                      "shouldRender",
                      shouldRender,
                      "Row span",
                      rowSpan,
                      "Slot: ",
                      facility.slot,
                      "Row time: ",
                      row.time,
                      "Booking exists",
                      !facility.booking,
                      "Booking is at row: ",
                      facility.booking?.bookingDateTimeUtc.getTime() ===
                        row.time.getTime(),
                      "Booking start time: ",
                      facility.booking?.bookingDateTimeUtc,
                      "Booking end time: ",
                      facility.booking?.bookingEndTimeUtc,
                      "Slot start time: ",
                      facility.slot?.startTime
                    );
                  }
                  return shouldRender ? (
                    <TableCell
                      key={`${facility.facilityName}-${row.time.toISOString()}`}
                      sx={{
                        width: facilityColumnWidth,
                        height: cellHeight,
                        padding: 0,
                        // Set minimum height based on rowSpan
                        minHeight: `${parseInt(cellHeight) * rowSpan}px`,
                      }}
                      rowSpan={rowSpan}
                    >
                      {facility.slot && (
                        <BookingCell
                          slot={facility.slot}
                          booking={facility.booking || null}
                          bookingIntervalMins={facility.bookingIntervalMins}
                          facilityName={facility.facilityName}
                          onCellClick={handleCellClick}
                        />
                      )}
                    </TableCell>
                  ) : null;
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  // Update the MobileView in BookingScheduleTable.tsx

  // Update the MobileView in BookingScheduleTable.tsx

  const MobileView = () => (
    <div
      className="mobile-schedule-container"
      style={{
        width: "100%",
        backgroundColor: "#fff",
        borderRadius: "4px",
        boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "8px",
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <IconButton
          onClick={() =>
            !isAnimating &&
            currentFacilityIndex > 0 &&
            handleFacilityChange(currentFacilityIndex - 1, "right")
          }
          disabled={currentFacilityIndex === 0 || isAnimating}
        >
          <ChevronLeft />
        </IconButton>
        <Typography variant="h6">
          {schedules[currentFacilityIndex]?.facilityName}
        </Typography>
        <IconButton
          onClick={() =>
            !isAnimating &&
            currentFacilityIndex < schedules.length - 1 &&
            handleFacilityChange(currentFacilityIndex + 1, "left")
          }
          disabled={
            currentFacilityIndex === schedules.length - 1 || isAnimating
          }
        >
          <ChevronRight />
        </IconButton>
      </div>

      <div
        {...handlers}
        style={{
          overflow: "hidden",
          position: "relative",
          width: "100%",
          flex: 1,
        }}
      >
        <div
          ref={contentRef}
          style={{
            transform: `translateX(${swipeAnimation}px)`,
            transition:
              swipeAnimation === 0 ? "transform 0.3s ease-out" : "none",
            width: "100%",
            height: "100%",
          }}
        >
          <div
            style={{
              width: "100%",
              overflow: "hidden",
            }}
          >
            <Table
              size="small"
              sx={{
                width: "100%",
                tableLayout: "fixed",
                "& td": {
                  boxSizing: "border-box",
                },
              }}
            >
              <TableBody>
                {timeSlots.map((timeSlot) => (
                  <TableRow key={timeSlot.toISOString()}>
                    <TableCell
                      sx={{
                        width: "30%",
                        padding: "8px",
                        whiteSpace: "nowrap",
                        borderRight: 1,
                        borderColor: "divider",
                      }}
                    >
                      {formatTimeSlot(timeSlot)}
                    </TableCell>
                    {(() => {
                      const currentSchedule = schedules[currentFacilityIndex];
                      const cellData = getBookingAndSlotForCell(
                        currentSchedule,
                        timeSlot
                      );

                      if (!cellData) {
                        return (
                          <TableCell
                            sx={{
                              width: "70%",
                              padding: 0,
                            }}
                          />
                        );
                      }

                      if (cellData.skipRender) return null;

                      return (
                        <TableCell
                          sx={{
                            width: "70%",
                            padding: 0,
                          }}
                          rowSpan={
                            cellData.booking
                              ? getRowSpan(
                                  cellData.booking,
                                  currentSchedule.bookingIntervalMins
                                )
                              : 1
                          }
                        >
                          <BookingCell
                            slot={cellData.slot}
                            booking={cellData.booking}
                            bookingIntervalMins={
                              currentSchedule.bookingIntervalMins
                            }
                            facilityName={cellData.facilityName}
                            onCellClick={handleCellClick}
                          />
                        </TableCell>
                      );
                    })()}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {isMobile ? <MobileView /> : <DesktopView />}

      {selectedBookingData && (
        <>
          <MakeBooking
            booking={selectedBookingData.booking}
            facilityName={selectedBookingData.facilityName}
            onClose={handleCloseModals}
            slot={selectedBookingData.slot}
          />
          <AvailableSlotCard
            slot={selectedBookingData.slot}
            booking={selectedBookingData.booking}
            facilityName={selectedBookingData.facilityName}
          />
        </>
      )}
    </>
  );
};

export default BookingScheduleTable;
