import React, { useState, useEffect } from 'react';
import { usePaymentService } from "../../services/payments/PaymentServiceProvider";
import { useMemberService } from "../../services/members/MemberServiceProvider";
import { AgGridReact } from 'ag-grid-react';
import { ColDef, RowClassParams } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {
    Button,
    Select,
    Alert,
    Space,
    Upload,
    Card,
    Typography,
    message as antdMessage,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { DirectDebitRun } from "../../model/payments/DirectDebitRun";
import { DirectDebitRunPayment } from "../../model/payments/DirectDebitRunPayment";
import { DirectDebitRejection } from "../../model/payments/DirectDebitRejection";
import { ProcessDirectDebitRejectionRequest } from '../../requests/payments/ProcessDirectDebitRejectionRequest';
import './DirectDebitRunManager.css';
import {AlertMessage} from "../common/AlertMessage";

const { Title } = Typography;

const DirectDebitRunManager: React.FC = () => {
    const paymentService = usePaymentService();
    const memberService = useMemberService();

    const [directDebitRuns, setDirectDebitRuns] = useState<DirectDebitRun[]>([]);
    const [selectedRun, setSelectedRun] = useState<DirectDebitRun | null>(null);
    const [rowData, setRowData] = useState<DirectDebitRunPayment[]>([]);
    const [message, setMessage] = useState<AlertMessage | null>(null);
    const [file, setFile] = useState<File | null>(null);
    const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);

    useEffect(() => {
        const fetchDirectDebitRuns = async () => {
            const runs = await paymentService.getDirectDebitRuns();
            setDirectDebitRuns(runs.sort((a, b) => new Date(b.paymentDate).getDate() - new Date(a.paymentDate).getDate()));
        };
        fetchDirectDebitRuns();
    }, []);

    const currencyFormatter = (params: { value: number }) => {
        return `£${params.value.toFixed(2)}`;
    };

    const columnDefs: ColDef[] = [
        { headerName: 'Membership ID', field: 'membershipId' },
        { headerName: 'Amount', field: 'amount', valueFormatter: currencyFormatter },
        { headerName: 'Reference', field: 'reference' },
        {
            headerName: 'Payment Status',
            field: 'paymentStatus',
            editable: true,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ['Pending', 'Completed', 'Failed', 'Cancelled', 'Rejected'],
            },
        },
        { headerName: 'Name', field: 'accountHolderName' },
    ];

    const getRowClass = (params: RowClassParams): string => {
        switch (params.data.paymentStatus) {
            case 'Pending':
                return 'bg-yellow';
            case 'Completed':
                return 'bg-green';
            case 'Failed':
                return 'bg-red';
            case 'Cancelled':
                return 'bg-grey';
            case 'Rejected':
                return 'bg-light-red';
            default:
                return '';
        }
    };

    const handleRunChange = async (value: Date) => {
        const run = directDebitRuns.find(r => new Date(r.paymentDate).toDateString() === value.toDateString());
        if (run) {
            setSelectedRun(run);
            const payments = run.payments;
            setRowData(payments);
        }
    };

    const handleProcessRejections = async (info: any) => {
        const file = info.file.originFileObj;
        if (file) {
            setFile(file);
            setUploadedFileName(file.name);
            const rejections = await processRejectionFile(file);
            if (rejections.length === 0) {
                antdMessage.info('No rejections found.');
                setMessage({ type: 'info', text: 'No rejections found.' });
            } else {
                const updatedRowData = rowData.map(row => {
                    const rejection = rejections.find(r => r.reference === row.reference);
                    if (rejection) {
                        return { ...row, paymentStatus: rejection.status };
                    }
                    return row;
                });
                setRowData(updatedRowData);
            }
        }
    };

    const processRejectionFile = (file: File): Promise<DirectDebitRejection[]> => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const content = e.target?.result as string;
                const lines = content.split('\n').slice(1); // Skip header line
                const rejections = lines.map(line => {
                    const [accountHolderName, sortCode, accountNumber, reference, amount, status, paymentDate] = line.split(',');
                    return {
                        accountHolderName,
                        sortCode,
                        accountNumber,
                        reference,
                        amount: parseFloat(amount),
                        status,
                        paymentDate: new Date(paymentDate)
                    } as DirectDebitRejection;
                });
                resolve(rejections);
            };
            reader.readAsText(file);
        });
    };

    const handleSaveUpdatedRun = async () => {
        if (!selectedRun || !file) return;

        const rejections = await processRejectionFile(file);

        console.log('Processing rejections:', rejections);
        const request: ProcessDirectDebitRejectionRequest = {
            runId: selectedRun.runId,
            rejections: rejections
        };

        try {
            console.log('Processing rejections:', request);
            await paymentService.processDirectDebitRejection(request);
            setMessage({ type: 'success', text: 'Direct Debit Run Completed' });
            antdMessage.success('Rejections processed successfully.');
        } catch (error) {
            setMessage({ type: 'error', text: 'Error processing Direct Debit Run' });
        }
    };

    return (
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
            <Title level={2}>Direct Debit Run Manager</Title>

            <Card>
                <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                    <Title level={4} style={{ marginTop: 0 }}>Select Direct Debit Run</Title>
                    <Space align="center" style={{ width: '100%', justifyContent: 'space-between' }}>
                        <Space>
                            <Select
                                onChange={(value) => handleRunChange(new Date(value))}
                                value={selectedRun ? new Date(selectedRun.paymentDate).toDateString() : new Date()}
                                placeholder="Select a Direct Debit Run"
                                style={{ width: '300px' }}
                            >
                                {directDebitRuns.map(run => (
                                    <Select.Option
                                        key={new Date(run.paymentDate).toDateString()}
                                        value={new Date(run.paymentDate).toDateString()}
                                    >
                                        {new Date(run.paymentDate).toDateString()}
                                    </Select.Option>
                                ))}
                            </Select>
                            {selectedRun && (
                                <Typography.Text>
                                    Payment Date: {new Date(selectedRun.paymentDate).toDateString()}
                                </Typography.Text>
                            )}
                        </Space>
                    </Space>
                </Space>
            </Card>

            <Card>
                <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                    <Title level={4} style={{ marginTop: 0 }}>Rejections</Title>

                    <Space align="center" style={{ width: '100%', justifyContent: 'space-between' }}>
                        <Space align="center">
                            <Upload
                                accept=".csv"
                                beforeUpload={(file) => {
                                    handleProcessRejections({ file: { originFileObj: file } });
                                    return false;  // Prevent default upload behavior
                                }}
                                showUploadList={false}
                            >
                                <Button icon={<UploadOutlined />}>Choose Rejections File</Button>
                            </Upload>
                            <Typography.Text>
                                {uploadedFileName
                                    ? `File selected: ${uploadedFileName}`
                                    : 'No file chosen'}
                            </Typography.Text>
                        </Space>

                        <Button
                            type="primary"
                            onClick={handleSaveUpdatedRun}
                            disabled={!file}
                        >
                            Save Updated Run
                        </Button>
                    </Space>
                </Space>
            </Card>

            <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                <AgGridReact
                    columnDefs={columnDefs}
                    rowData={rowData}
                    getRowClass={getRowClass}
                    defaultColDef={{
                        flex: 1,
                        minWidth: 100,
                        filter: true,
                        sortable: true,
                        resizable: true,
                    }}
                />
            </div>

            {message && (
                <Alert
                    type={message.type}
                    message={message.text}
                    showIcon
                />
            )}
        </Space>
    );
};

export default DirectDebitRunManager;