export abstract class ClubBookingApiClient {
    protected readonly clubbookingApiUrl: string;
    private readonly getToken: () => Promise<string>;
    private readonly clientId: string | null;

    constructor(getToken: () => Promise<string>, clientId: string | null) {
        this.clubbookingApiUrl = process.env.REACT_APP_CLUBBOOKING_API_URL || '';
        this.getToken = getToken;
        this.clientId = clientId;
    }

    protected async getHeaders(): Promise<Headers> {
        const token = await this.getToken();
        const headers = new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        });

        if (this.clientId) {
            headers.set('X-Client-Id', this.clientId);
        }

        return headers;
    }

    protected async get<T>(endpoint: string): Promise<T> {
        const headers = await this.getHeaders();
        const response = await fetch(`${this.clubbookingApiUrl}${endpoint}`, {
            headers
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.json();
    }

    protected async post<T = void>(endpoint: string, data?: any): Promise<T> {
        const headers = await this.getHeaders();
        const response = await fetch(`${this.clubbookingApiUrl}${endpoint}`, {
            method: 'POST',
            headers,
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        if (response.status === 204) {
            return undefined as T;
        }

        if (response.bodyUsed) {
            return response.json();
        }

        return undefined as T;
    }
}