import {HealthQuestionnaireService} from "./HealthQuestionnaireService";
import React from "react";
import {useClient} from "../../../common/ClientProvider";
import {useAuth0} from "@auth0/auth0-react";

interface HealthServiceProviderProps {
    children: React.ReactNode;  // Add this line
}

// Create a context for the booking service
export const HealthServiceContext = React.createContext<HealthQuestionnaireService | undefined>(undefined);

// Create a provider component for the booking service
export const HealthServiceProvider: React.FC<HealthServiceProviderProps> = ({ children }) => {
    const { getAccessTokenSilently } = useAuth0();
    const { currentClientId } = useClient();

    const service = React.useMemo(() => {
        return new HealthQuestionnaireService(getAccessTokenSilently, currentClientId);
    }, [getAccessTokenSilently, currentClientId]);

    return (
        <HealthServiceContext.Provider value={service}>
            {children}
        </HealthServiceContext.Provider>
    );
};

// Create a hook to use the booking service
export const useHealthService = (): HealthQuestionnaireService => {
    const context = React.useContext(HealthServiceContext);
    if (context === undefined) {
        throw new Error('useHealthService must be used within a HealthServiceProvider');
    }
    return context;
};