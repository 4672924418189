import {FacilityScheduleForDay} from "../../model/bookings/FacilityScheduleForDay";
import {CreateBookingRequest} from "../../model/bookings/CreateBookingRequest";
import {CancelBookingRequest} from "../../model/bookings/CancelBookingRequest";
import {ClubBookingApiClient} from "../../common/api/ClubBookingApiClient";

export class BookingService extends ClubBookingApiClient {
    async getBookingsForFacilityType(
        from: Date,
        to: Date,
        facilityType: string
    ): Promise<FacilityScheduleForDay[]> {
        const fromUTC = from.toISOString().split('T')[0];
        const toUTC = to.toISOString().split('T')[0];

        const data = await this.get<FacilityScheduleForDay[]>(
            `/bookings?from=${fromUTC}&to=${toUTC}&facilityType=${facilityType}`
        );

        return data.map(schedule => this.parseDatesInSchedule(schedule));
    }

    async saveBooking(bookingRequest: CreateBookingRequest): Promise<void> {
        try {
            await this.post('/bookings/create', bookingRequest);
        } catch(error) {
            console.error('Error saving booking:', error);
            throw error;
        }
    }

    async cancelBooking(cancelBookingRequest: CancelBookingRequest): Promise<void> {
        await this.post('/bookings/cancel', cancelBookingRequest);
    }

    private parseDatesInSchedule(schedule: FacilityScheduleForDay): FacilityScheduleForDay {
        return {
            ...schedule,
            date: new Date(schedule.date),
            slots: schedule.slots.map(slot => ({
                ...slot,
                startTime: new Date(slot.startTime),
                endTime: new Date(slot.endTime)
            })),
            bookings: schedule.bookings.map(booking => ({
                ...booking,
                bookingDateTimeUtc: new Date(booking.bookingDateTimeUtc),
                bookingEndTimeUtc: new Date(booking.bookingEndTimeUtc)
            }))
        };
    }
}