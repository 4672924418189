import { Member } from "../../model/members/Member";
import { CreateMemberRequest } from "../../requests/member/CreateMemberRequest";
import { UpdateMemberRequest } from "../../requests/member/UpdateMemberRequest";
import { MemberProfile } from "../../model/members/MemberProfile";
import {ClubBookingApiClient} from "../../common/api/ClubBookingApiClient";
import { PlayingMember } from "../../model/members/PlayingMember";

export class MemberService extends ClubBookingApiClient {
    async getMembers(dataSource: string): Promise<Member[]> {
        console.log('Requesting members for data source');
        return this.get<Member[]>('/members');
    }

    async getPlayingMembers(): Promise<PlayingMember[]> {
        console.log('Requesting playing members');
        return this.get<PlayingMember[]>('/members/playing');
    }

    async getMemberProfiles(): Promise<MemberProfile[]> {
        console.log('Requesting member profiles');
        return this.get<MemberProfile[]>('/members');
    }

    async create(request: CreateMemberRequest): Promise<MemberProfile> {
        console.log('Creating member:', request);
        return this.post<MemberProfile>('/members/create', request);
    }

    async update(request: UpdateMemberRequest): Promise<void> {
        console.log('Updating member:', request);
        return this.post('/members/update', request);
    }
}