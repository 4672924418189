import {CreateDirectDebitRequest} from "../../requests/payments/CreateDirectDebitRequest";
import {CreateDirectDebitFileRequest} from "../../requests/payments/CreateDirectDebitFileRequest";
import {DirectDebitFile} from "../../model/payments/DirectDebitFile";
import {DirectDebitRun} from "../../model/payments/DirectDebitRun";
import {DirectDebitMemberProfile} from "../../model/payments/DirectDebitMemberProfile";
import { ProcessDirectDebitRejectionRequest } from "../../requests/payments/ProcessDirectDebitRejectionRequest";
import {ClubBookingApiClient} from "../../common/api/ClubBookingApiClient";

export class PaymentService extends ClubBookingApiClient {
    async createDirectDebit(request: CreateDirectDebitRequest): Promise<void> {
        console.log('Creating direct debit:', request);
        await this.post('/payments/create/instruction', request);
    }

    async createDirectDebitFile(request: CreateDirectDebitFileRequest): Promise<Blob> {
        console.log('Creating direct debit file for', request.directDebitPaymentRequests.length, 'members on', request.paymentDate.toDateString());
        const directDebitFile = await this.post<DirectDebitFile>('/payments/create/directdebitfile', request);
        const fileContent = directDebitFile.directDebitLines.join('\n');
        return new Blob([fileContent], { type: 'text/plain' });
    }

    async getDirectDebitRuns(): Promise<DirectDebitRun[]> {
        console.log('Fetching direct debit runs');
        return this.get<DirectDebitRun[]>('/payments/directdebit/runs');
    }

    async getActiveDirectDebitMembers(): Promise<DirectDebitMemberProfile[]> {
        console.log('Fetching active direct debit members');
        return this.get<DirectDebitMemberProfile[]>('/payments/directdebit/active');
    }

    async processDirectDebitRejection(request: ProcessDirectDebitRejectionRequest): Promise<void> {
        console.log('Processing direct debit rejection:', request);
        await this.post('/payments/directdebit/rejections', request);
    }
}