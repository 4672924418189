import {
  Button,
  Typography,
  Space,
  Divider,
  Select,
  Alert,
  Modal,
  Tag,
  Flex,
} from "antd";
import { Slot } from "../../model/bookings/Slot";
import { useBookingService } from "../../services/bookings/BookingServiceProvider";
import { Booking } from "../../model/bookings/Booking";
import React, { useState, useEffect, useRef } from "react";
import { CreateBookingRequest } from "../../model/bookings/CreateBookingRequest";
import moment from "moment";
import { useMemberService } from "../../services/members/MemberServiceProvider";
import { useQuery } from "react-query";
import { AlertMessage } from "../common/AlertMessage";
import { CancelBookingRequest } from "../../model/bookings/CancelBookingRequest";
import { useClient } from "../../common/ClientProvider";
import { PlayingMember } from "../../model/members/PlayingMember";

interface MakeBookingProps {
  facilityName: string;
  slot: Slot;
  booking: Booking | null;
  onClose: (wasSuccessful: boolean) => void;
}

function createDefaultBooking(court: string, startTime: Date): Booking {
  const endTime = new Date(startTime);
  endTime.setMinutes(endTime.getMinutes() + 60);

  return {
    bookingDateTimeUtc: startTime,
    bookingEndTimeUtc: endTime,
    facilityName: court,
    bookingStatus: "Available",
    paymentStatus: "Unpaid",
    memberName: "",
    membershipId: "",
    playingMembers: [],
    // Add other properties as needed, with appropriate default values
  };
}

const MakeBooking: React.FC<MakeBookingProps> = ({
  facilityName,
  slot,
  booking: initialBooking,
  onClose,
}) => {
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const { currentClientId } = useClient();
  const [booking, setBooking] = useState<Booking>(
    initialBooking || createDefaultBooking(facilityName, slot.startTime)
  );
  const initialBookingRef = useRef<Booking>(
    initialBooking || createDefaultBooking(facilityName, slot.startTime)
  );

  const bookingService = useBookingService();
  const memberService = useMemberService();

  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const [bookingLengthMins, setBookingLengthMins] = useState(
    moment(booking.bookingEndTimeUtc).diff(
      moment(booking.bookingDateTimeUtc),
      "minutes"
    ) || 60
  );
  const initialBookingLengthMins = useRef(bookingLengthMins);
  const [message, setMessage] = useState<AlertMessage | null>(null);
  const [showAlert, setShowAlert] = useState(false);

  const fetchMembers = async (): Promise<PlayingMember[]> => {
    console.log("Fetching members");
    return await memberService.getPlayingMembers();
  };

  const {
    data: members,
    isLoading: membersLoading,
    error: membersError,
  } = useQuery("members", fetchMembers);

  useEffect(() => {
    setIsSaveButtonEnabled(
      hasBookingChanges(
        booking,
        initialBookingRef.current,
        bookingLengthMins,
        initialBookingLengthMins.current
      )
    );
  }, [booking, bookingLengthMins]);

  const setErrorMessage = (error: string) => {
    setMessage({ type: "error", text: error });
    setShowAlert(true);

    // Optionally auto-hide after a few seconds
    setTimeout(() => {
      setShowAlert(false);
    }, 5000);
  };

  const hasBookingChanges = (
    currentBooking: Booking,
    initialBooking: Booking,
    currentLength: number,
    initialLength: number
  ): boolean => {
    return (
      currentBooking.memberName !== initialBooking.memberName ||
      currentBooking.membershipId !== initialBooking.membershipId ||
      currentBooking.playingMembers.length !==
        initialBooking.playingMembers.length ||
      !currentBooking.playingMembers.every((member) =>
        initialBooking.playingMembers.includes(member)
      ) ||
      currentLength !== initialLength
    );
  };

  const handleOpenCancelModal = () => {
    setIsCancelModalVisible(true);
  };

  const handleCancelBooking = async () => {
    const cancelBookingRequest: CancelBookingRequest = {
      bookingDateTimeUtc: booking.bookingDateTimeUtc,
      facilityName: booking.facilityName,
      membershipId: booking.membershipId,
      requestingUsername: booking.memberName, // TODO: Replace with actual username
    };

    console.log("Cancelling booking:", cancelBookingRequest);
    await bookingService.cancelBooking(cancelBookingRequest);
    onClose(true);
  };

  const handleSaveBooking = async () => {
    const endTime = new Date(slot.startTime);
    endTime.setMinutes(endTime.getMinutes() + bookingLengthMins);

    if (!booking.membershipId) {
      setMessage({
        type: "error",
        text: "Main booking member has not been selected",
      });
      return;
    }

    try {
      const mainMember = members?.find(
        (m) => m.membershipId === booking.membershipId
      );
      const playingMembers = booking.playingMembers
        .map((name) => members?.find((m) => m.name === name))
        .filter((m): m is PlayingMember => m !== undefined);

      if (mainMember) {
        if (
          !playingMembers.some(
            (m) => m.membershipId === mainMember.membershipId
          )
        ) {
          playingMembers.push(mainMember);
        }

        if (currentClientId === undefined) {
          throw new Error("Current client ID is undefined");
        }

        const createBookingRequest: CreateBookingRequest = {
          slot: slot,
          startTime: slot.startTime,
          endTime,
          mainBookingMember: mainMember,
          facilityName: booking.facilityName,
          selectedMembers: playingMembers,
          clientName: currentClientId,
        };

        await bookingService.saveBooking(createBookingRequest);
        setIsSaveButtonEnabled(false);
        onClose(true);
      }
    } catch (error) {
      console.error("Error saving booking:", error);
      setErrorMessage(
        "Failed to save booking. Please try again. Error: " + error
      );
      onClose(false);
    }
  };

  const handleMainMemberChange = (membershipId: string) => {
    const member = members?.find((m) => m.membershipId === membershipId);
    if (member) {
      setBooking((prev) => ({
        ...prev,
        memberName: member.name,
        membershipId: member.membershipId,
      }));

      if (
        !booking.playingMembers.some(
          (name) => name === member.name && member.membershipId === membershipId
        )
      ) {
        setBooking((prev) => ({
          ...prev,
          playingMembers: [...prev.playingMembers, member.name],
        }));
      }

      setIsSaveButtonEnabled(true);
    }
  };

  const handlePlayingMembersChange = (membershipIds: string[]) => {
    const selectedMembers = membershipIds
      .map((id) => members?.find((m) => m.membershipId === id))
      .filter((m): m is PlayingMember => m !== undefined);

    setBooking((prev) => ({
      ...prev,
      playingMembers: selectedMembers.map((m) => m.name),
    }));
    setIsSaveButtonEnabled(true);
  };

  const handleRemovePlayingMember = (memberToRemove: string) => {
    setBooking((prev) => ({
      ...prev,
      playingMembers: prev.playingMembers.filter(
        (name) => name !== memberToRemove
      ),
    }));
    setIsSaveButtonEnabled(true);
  };

  if (membersLoading) return <div>Loading...</div>;
  if (membersError) return <div>Error: {(membersError as Error).message}</div>;

  return (
    <>
      <Modal
        open={true}
        maskClosable={false}
        closable={true}
        onCancel={() => onClose(false)}
        footer={null}
        width={500}
        centered={true}
      >
        {message && showAlert && (
          <Alert type={message.type} message={message.text} showIcon />
        )}
        <Space direction="vertical" style={{ width: "90%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography.Text>
              <strong>Court:</strong>
            </Typography.Text>
            <Typography.Text>{booking.facilityName}</Typography.Text>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography.Text>
              <strong>Date:</strong>
            </Typography.Text>
            <Typography.Text>
              {moment(booking.bookingDateTimeUtc).format("dddd Do MMMM YYYY")}
            </Typography.Text>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography.Text>
              <strong>Time:</strong>
            </Typography.Text>
            <Typography.Text>
              {moment(booking.bookingDateTimeUtc).format("HH:mm")} -{" "}
              {moment(booking.bookingEndTimeUtc).format("HH:mm")}
            </Typography.Text>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography.Text>
              <strong>Member:</strong>
            </Typography.Text>
            <Select
              style={{ minWidth: "200px" }}
              placeholder="Select member"
              value={booking.membershipId}
              onChange={handleMainMemberChange}
              options={members?.map((member) => ({
                label: member.name,
                value: member.membershipId,
              }))}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              showSearch
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography.Text>
              <strong>Booking Length:</strong>
            </Typography.Text>
            <Select value={bookingLengthMins} onChange={setBookingLengthMins}>
              <Select.Option value={30}>30 minutes</Select.Option>
              <Select.Option value={60}>60 minutes</Select.Option>
            </Select>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              width: "100%",
            }}
          >
            <Typography.Text>
              <strong>Playing Members:</strong>
            </Typography.Text>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                gap: "8px",
                width: "200px",
              }}
            >
              <Select
                mode="multiple"
                style={{ minWidth: "200px" }}
                placeholder="Select members"
                value={booking.playingMembers
                  .map((name) => members?.find((m) => m.name === name))
                  .filter(
                    (member): member is PlayingMember =>
                      member !== undefined && member !== null
                  )
                  .map((member) => member.membershipId)}
                onChange={handlePlayingMembersChange}
                onSelect={(value, option) => {
                  const selectElement = document.activeElement as HTMLElement;
                  selectElement?.blur();
                }}
                options={members
                  ?.filter(
                    (member) =>
                      !booking.playingMembers.includes(member.name) &&
                      member.membershipId !== booking.membershipId
                  )
                  .map((member) => ({
                    label: member.name,
                    value: member.membershipId,
                  }))}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                showSearch
                maxTagCount={0}
              />
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "8px",
                  justifyContent: "flex-start",
                  width: "100%",
                }}
              >
                {booking.playingMembers.map((memberName, index) => (
                  <Tag
                    key={memberName}
                    closable
                    onClose={() => handleRemovePlayingMember(memberName)}
                    style={{
                      margin: "2px",
                      borderRadius: "16px",
                      padding: "4px 12px",
                      backgroundColor: `hsl(${(index * 30) % 360}, 70%, 90%)`,
                      border: `1px solid hsl(${(index * 30) % 360}, 70%, 80%)`,
                      color: `hsl(${(index * 30) % 360}, 70%, 30%)`,
                    }}
                  >
                    {memberName}
                  </Tag>
                ))}
              </div>
            </div>
          </div>
        </Space>
        <Divider />
        <Flex justify="center">
          <Space>
            <Button
              type="primary"
              onClick={handleSaveBooking}
              disabled={!isSaveButtonEnabled}
            >
              Save Booking
            </Button>
            <Button type="primary" onClick={handleOpenCancelModal} danger>
              Cancel
            </Button>
          </Space>
        </Flex>
      </Modal>

      <Modal
        title=""
        centered={true}
        open={isCancelModalVisible}
        onCancel={() => setIsCancelModalVisible(false)}
        footer={[
          <Button key="back" onClick={() => setIsCancelModalVisible(false)}>
            No
          </Button>,
          <Button
            key="submit"
            type="primary"
            danger
            onClick={handleCancelBooking}
          >
            Yes
          </Button>,
        ]}
      >
        <p>Are you sure you want to cancel this booking?</p>
      </Modal>
    </>
  );
};

export default MakeBooking;
