import React from 'react';
import { MemberService } from "./MemberService";
import { useAuth0 } from '@auth0/auth0-react';
import {useClient} from "../../common/ClientProvider";

interface MemberServiceProviderProps {
    children: React.ReactNode;
}

// Create a context for the member service
export const MemberServiceContext = React.createContext<MemberService | undefined>(undefined);

// Create a provider component for the member service
export const MemberServiceProvider: React.FC<MemberServiceProviderProps> = ({ children }) => {
    const { getAccessTokenSilently } = useAuth0();
    const { currentClientId } = useClient();

    const service = React.useMemo(() => {
        return new MemberService(getAccessTokenSilently, currentClientId);
    }, [getAccessTokenSilently, currentClientId]);

    return (
        <MemberServiceContext.Provider value={service}>
            {children}
        </MemberServiceContext.Provider>
    );
};

// Create a hook to use the member service
export const useMemberService = (): MemberService => {
    const context = React.useContext(MemberServiceContext);
    if (context === undefined) {
        throw new Error('useMemberService must be used within a MemberServiceProvider');
    }
    return context;
};