import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Spin } from 'antd';

interface ClientContextType {
    currentClientId: string;
    setCurrentClientId: (clientId: string) => void;
    availableClients: string[];
    isSuperAdmin: boolean;
}

interface ClientRedirectConfig {
    clientId: string;
    url: string;
}

// Example redirect configuration - can be moved to environment variables or API later
const CLIENT_REDIRECTS: ClientRedirectConfig[] = [
    // { clientId: 'Other Club', url: 'https://otherclub.com' }
];

const ClientContext = createContext<ClientContextType | undefined>(undefined);

export const ClientProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { user, isAuthenticated, isLoading: authLoading } = useAuth0();
    const [isInitialized, setIsInitialized] = useState(false);
    const [currentClientId, setCurrentClientId] = useState<string | null>(
        localStorage.getItem('currentClientName')
    );
    const [availableClients, setAvailableClients] = useState<string[]>([]);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);

    const handleClientSwitch = (clientId: string) => {
        // Check for redirect configuration
        const redirectConfig = CLIENT_REDIRECTS.find(config => config.clientId === clientId);
        if (redirectConfig) {
            // Redirect to different website
            window.location.href = redirectConfig.url;
            return;
        }

        // Update local storage and state
        localStorage.setItem('currentClientName', clientId);
        setCurrentClientId(clientId);

        // Refresh the current page
        window.location.reload();
    };

    useEffect(() => {
        if (!authLoading && isAuthenticated && user) {
            const clients = user['fortius-app:clients'] || [];
            setAvailableClients(clients);
            setIsSuperAdmin(user['fortius-app:isSuperAdmin'] === true);

            if (clients.length > 0) {
                const savedClient = localStorage.getItem('currentClientName');
                const validSavedClient = savedClient && clients.includes(savedClient);

                if (validSavedClient) {
                    setCurrentClientId(savedClient);
                } else {
                    const defaultClient = clients[0];
                    setCurrentClientId(defaultClient);
                    localStorage.setItem('currentClientName', defaultClient);
                }
            }
            setIsInitialized(true);
        }
    }, [user, isAuthenticated, authLoading]);

    if (authLoading) {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh'
            }}>
                <Spin size="large" tip="Loading client information..." />
            </div>
        );
    }

    if (!isAuthenticated) {
        return <>{children}</>;
    }

    if (!isInitialized || !currentClientId) {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh'
            }}>
                <Spin size="large" tip="Loading client information..." />
            </div>
        );
    }

    return (
        <ClientContext.Provider value={{
            currentClientId,
            setCurrentClientId: handleClientSwitch,
            availableClients,
            isSuperAdmin
        }}>
            {children}
        </ClientContext.Provider>
    );
};

export const useClient = (): ClientContextType => {
    const context = useContext(ClientContext);
    if (!context) {
        throw new Error('useClient must be used within a ClientProvider');
    }
    return context;
};