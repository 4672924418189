import React from 'react';
import { Typography, Card, Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom';
import { CalendarOutlined, MailOutlined, TeamOutlined, BookOutlined } from '@ant-design/icons';

import styled from 'styled-components';
import { useClient } from './common/ClientProvider';

const { Title } = Typography;

const StyledCard = styled(Card)`
  height: 200px;
  margin: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;

  .card-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;
  }

  .card-description {
    position: absolute;
    bottom: -100%;
    left: 0;
    right: 0;
    background: rgba(0, 0, 90, 0.9);
    color: white;
    padding: 16px;
    transition: bottom 0.3s ease;
    text-align: center;
  }

  &:hover {
    box-shadow: 0 4px 15px rgba(0, 0, 90, 0.2);
    
    .card-content {
      transform: translateY(-10px);
    }
    
    .card-description {
      bottom: 0;
    }
  }
`;

const IconWrapper = styled.div`
  font-size: 48px;
  margin-bottom: 16px;
  color: #00005a;
`;

interface TileProps {
  title: string;
  icon: React.ReactNode;
  description: string;
  onClick: () => void;
}

const Tile: React.FC<TileProps> = ({ title, icon, description, onClick }) => (
  <StyledCard onClick={onClick}>
    <div className="card-content">
      <IconWrapper>{icon}</IconWrapper>
      <Title level={4} style={{ margin: 0 }}>{title}</Title>
    </div>
    <div className="card-description">
      {description}
    </div>
  </StyledCard>
);

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const { currentClientId } = useClient();

  const handleContact = () => {
    window.location.href = `mailto:contact@${currentClientId.toLowerCase().replace(' ', '')}.com`;
  };

  return (
    <div style={{ padding: '24px' }}>
      <Title style={{ 
        textAlign: 'center', 
        marginBottom: '48px',
        color: '#00005a'
      }}>
        Welcome to {currentClientId}!
      </Title>

      <Row gutter={[16, 16]} justify="center" style={{ maxWidth: 1200, margin: '0 auto' }}>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <Tile
            title="Bookings"
            icon={<CalendarOutlined />}
            description={`Book a facility at ${currentClientId}`}
            onClick={() => navigate('/bookings/facilities')}
          />
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <Tile
            title="Contact"
            icon={<MailOutlined />}
            description={`Contact ${currentClientId}`}
            onClick={handleContact}
          />
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <Tile
            title="Find an Opponent"
            icon={<TeamOutlined />}
            description="Find an opponent to play with"
            onClick={() => navigate('/find')}
          />
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <Tile
            title="Book a Class"
            icon={<BookOutlined />}
            description={`Book a class at ${currentClientId}`}
            onClick={() => navigate('/classes')}
          />
        </Col>
      </Row>
    </div>
  );
};

export default HomePage;