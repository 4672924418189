import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Button, DatePicker, message, Space, notification } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { AgGridReact } from 'ag-grid-react';
import {
    ColDef,
    GridReadyEvent,
    ValueFormatterParams,
    ValueParserParams,
    GridApi,
    RowSelectionOptions
} from "ag-grid-community";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import moment from 'moment';
import { useMemberService } from "../../services/members/MemberServiceProvider";
import { usePaymentService } from "../../services/payments/PaymentServiceProvider";
import { DirectDebitMemberProfile } from "../../model/payments/DirectDebitMemberProfile";
import { CreateDirectDebitFileRequest } from "../../requests/payments/CreateDirectDebitFileRequest";
import {CreateDirectDebitPaymentRequest} from "../../requests/payments/CreateDirectDebitPaymentRequest";

const DirectDebitManagement: React.FC = () => {
    const paymentService = usePaymentService();
    const memberService = useMemberService();
    const [memberProfiles, setMemberProfiles] = useState<DirectDebitMemberProfile[]>([]);
    const [selectedDate, setSelectedDate] = useState<moment.Moment>(moment().endOf('month'));
    const [gridApi, setGridApi] = useState<GridApi | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        fetchMemberProfiles();
    }, [memberService]);

    const fetchMemberProfiles = async () => {
        try {
            const directDebitMemberProfiles = await paymentService.getActiveDirectDebitMembers();
            console.log('Fetched member profiles:', directDebitMemberProfiles);
            setMemberProfiles(directDebitMemberProfiles);
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'Failed to fetch member profiles',
            });
        }
    };

    const currencyFormatter = useCallback((params: ValueFormatterParams) => {
        if (typeof params.value === 'number') {
            return new Intl.NumberFormat('en-GB', {
                style: 'currency',
                currency: 'GBP',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(params.value);
        }
        return '';
    }, []);

    const currencyParser = useCallback((params: ValueParserParams) => {
        if (typeof params.newValue === 'string') {
            const numberValue = Number(params.newValue.replace(/[^0-9.-]+/g, ""));
            return isNaN(numberValue) ? params.oldValue : numberValue;
        }
        return params.oldValue;
    }, []);

    const columnDefs = useMemo<ColDef<DirectDebitMemberProfile>[]>(() => [
        {
            headerName: 'Name',
            field: 'profile.name',
            filter: 'agTextColumnFilter',
            editable: false,
        },
        {
            headerName: 'Email',
            field: 'profile.emailAddress',
            filter: 'agTextColumnFilter',
            editable: false,
        },
        {
            headerName: 'Status',
            field: 'profile.status',
            filter: 'agTextColumnFilter',
            editable: false,
        },
        {
            headerName: 'Membership ID',
            field: 'profile.membershipId',
            filter: 'agTextColumnFilter',
            editable: false,
        },
        {
            headerName: 'Payment Amount',
            field: 'profile.membershipCategory.amount',
            filter: 'agNumberColumnFilter',
            editable: true,
            valueFormatter: (params: ValueFormatterParams<DirectDebitMemberProfile, number>) => currencyFormatter(params),
            valueParser: (params: ValueParserParams) => currencyParser(params),
        },
        {
            headerName: 'Membership Category',
            field: 'profile.membershipCategory.name',
            filter: 'agTextColumnFilter',
            editable: false,
        },
    ], [currencyFormatter, currencyParser]);

    const onGridReady = useCallback((params: GridReadyEvent<DirectDebitMemberProfile>) => {
        setGridApi(params.api);
    }, []);

    const selectActiveMembers = useCallback(() => {
        if (gridApi) {
            gridApi.forEachNode((node) => {
                if (node.data.profile.status === 'Active') {
                    node.setSelected(true);
                }
            });
        }
    }, [gridApi]);

    const createDirectDebitFile = async () => {
        if (!gridApi) return;

        const selectedNodes = gridApi.getSelectedRows();
        if (selectedNodes.length === 0) {
            message.warning('Please select at least one member');
            return;
        }

        setLoading(true);
        try {
            const request: CreateDirectDebitFileRequest = {
                paymentDate: selectedDate.toDate(),
                directDebitPaymentRequests: selectedNodes.map((member: DirectDebitMemberProfile): CreateDirectDebitPaymentRequest => ({
                    membershipId: member.profile.membershipId,
                    paymentDate: selectedDate.toDate(),
                    amount: member.profile.membershipCategory.amount,
                })),
            };
            const fileBlob = await paymentService.createDirectDebitFile(request);

            const url = window.URL.createObjectURL(fileBlob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `direct_debit_${selectedDate.format('YYYY-MM-DD')}.csv`;
            document.body.appendChild(a);
            a.click();

            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);

            message.success('Direct debit file created successfully');
        } catch (error) {
            console.error('Failed to create direct debit file:', error);
            message.error('Failed to create direct debit file');
        } finally {
            setLoading(false);
        }
    };

    const handleDateChange = (date: any) => {
        console.log('Selected date:', date.toDate());
        if (selectedDate !== date.toDate()) {
            setSelectedDate(date.toDate());
        }
        // setFormattedDate(date.format('dddd Do MMMM YYYY'));
    };

    const rowSelection = useMemo<
        RowSelectionOptions | "single" | "multiple"
    >(() => {
        return {
            mode: "multiRow",
            checkboxes: true,
            headerCheckbox: true,
            enableClickSelection: true,
        };
    }, []);

    return (
        <div style={{ padding: '24px' }}>
            <h1 style={{ fontSize: '24px', marginBottom: '16px' }}>Direct Debit Management</h1>
            <Space style={{ marginBottom: '16px' }}>
                <DatePicker
                    value={selectedDate}
                    onChange={handleDateChange}
                />
                <Button
                    type="primary"
                    icon={<DownloadOutlined />}
                    onClick={createDirectDebitFile}
                    loading={loading}
                >
                    Create Direct Debit File
                </Button>
            </Space>
            <div style={{ marginBottom: '16px' }}>
                <Button onClick={selectActiveMembers}>Select Active Members</Button>
            </div>
            <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                <AgGridReact
                    columnDefs={columnDefs}
                    rowData={memberProfiles}
                    onGridReady={onGridReady}
                    defaultColDef={{
                        flex: 1,
                        minWidth: 100,
                        filter: true,
                        sortable: true,
                        resizable: true,
                    }}
                    rowSelection={rowSelection}
                    suppressRowClickSelection={true}
                />
            </div>
        </div>
    );
};

export default DirectDebitManagement;