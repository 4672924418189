import React, {useState, useCallback, useEffect} from 'react';
import { Box, Typography } from '@mui/material';
import { useSwipeable } from 'react-swipeable';
import {
    startOfWeek,
    endOfWeek,
    eachDayOfInterval,
    isSameDay,
    addWeeks,
    subWeeks,
    isSameMonth
} from 'date-fns';

interface CompactWeekCalendarProps {
    selectedDate: Date;
    onChange: (date: Date) => void;
    onCalendarOpen: () => void;
}

export const CompactWeekCalendar: React.FC<CompactWeekCalendarProps> = ({
                                                                            selectedDate,
                                                                            onChange,
                                                                            onCalendarOpen
                                                                        }) => {
    const [swipeAnimation, setSwipeAnimation] = useState(0);
    const [currentWeekStart, setCurrentWeekStart] = useState(() =>
        startOfWeek(selectedDate, { weekStartsOn: 0 })
    );

    useEffect(() => {
        setCurrentWeekStart(startOfWeek(selectedDate, { weekStartsOn: 0 }));
    }, [selectedDate]);

    const weekDays = eachDayOfInterval({
        start: currentWeekStart,
        end: endOfWeek(currentWeekStart, { weekStartsOn: 0 })
    });

    const handleNextWeek = useCallback(() => {
        setCurrentWeekStart(current => addWeeks(current, 1));
    }, []);

    const handlePrevWeek = useCallback(() => {
        setCurrentWeekStart(current => subWeeks(current, 1));
    }, []);

    const handleDateSelect = useCallback((date: Date) => {
        onChange(date);
        // Update current week to the week containing the selected date
        setCurrentWeekStart(startOfWeek(date, { weekStartsOn: 0 }));
    }, [onChange]);

    const handlers = useSwipeable({
        onSwipedLeft: () => {
            setSwipeAnimation(-100);
            setTimeout(() => {
                handleNextWeek();
                setSwipeAnimation(0);
            }, 200);
        },
        onSwipedRight: () => {
            setSwipeAnimation(100);
            setTimeout(() => {
                handlePrevWeek();
                setSwipeAnimation(0);
            }, 200);
        },
        onSwiping: (e) => {
            setSwipeAnimation(e.deltaX);
        },
        onSwiped: () => {
            setSwipeAnimation(0);
        },
        trackMouse: true,
        preventScrollOnSwipe: true,
    });

    // Find the month to display - if week spans two months, show the month with more days
    const monthToShow = (() => {
        const daysInFirstMonth = weekDays.filter(day =>
            isSameMonth(day, weekDays[0])
        ).length;
        const daysInSecondMonth = 7 - daysInFirstMonth;

        return daysInFirstMonth >= daysInSecondMonth ? weekDays[0] : weekDays[6];
    })();

    return (
        <Box sx={{
            overflow: 'hidden',
            borderRadius: 1,
            border: '1px solid',
            borderColor: 'divider',
            backgroundColor: 'background.paper',
            mb: 2
        }}>
            <Box
                sx={{
                    textAlign: 'center',
                    py: 1.5,
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                    cursor: 'pointer',
                    '&:hover': {
                        backgroundColor: 'action.hover',
                    }
                }}
                onClick={onCalendarOpen}
            >
                <Typography variant="h6" color="text.secondary" sx={{ fontWeight: 'normal' }}>
                    {monthToShow.toLocaleString('default', { month: 'long', year: 'numeric' })}
                </Typography>
            </Box>

            <Box {...handlers}>
                <Box sx={{
                    display: 'flex',
                    transform: `translateX(${swipeAnimation}px)`,
                    transition: swipeAnimation === 0 ? 'transform 0.2s ease-out' : 'none',
                }}>
                    {weekDays.map((day) => (
                        <Box
                            key={day.toISOString()}
                            onClick={() => handleDateSelect(day)}
                            sx={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                py: 1.5,
                                cursor: 'pointer',
                                minWidth: 0,
                                position: 'relative',
                                '&:hover': {
                                    backgroundColor: 'action.hover',
                                },
                                ...(isSameDay(day, selectedDate) && {
                                    backgroundColor: 'primary.main',
                                    color: 'primary.contrastText',
                                    '&:hover': {
                                        backgroundColor: 'primary.dark',
                                    },
                                }),
                            }}
                        >
                            <Typography
                                variant="caption"
                                sx={{
                                    color: isSameDay(day, selectedDate) ? 'inherit' : 'text.secondary',
                                    textTransform: 'uppercase',
                                    mb: 0.5,
                                    fontSize: '0.75rem'
                                }}
                            >
                                {day.toLocaleString('default', { weekday: 'short' })}
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    fontWeight: isSameDay(day, selectedDate) ? 'bold' : 'normal',
                                }}
                            >
                                {day.getDate()}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );
};