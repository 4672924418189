import React, { useState } from "react";
import { Button, Dropdown, Layout, Menu } from "antd";
import {
  CalendarOutlined,
  HomeOutlined,
  MenuOutlined,
  MoneyCollectFilled,
  UserAddOutlined,
} from "@ant-design/icons";
import {
  BrowserRouter as Router,
  Link,
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import { BookingServiceProvider } from "./services/bookings/BookingServiceProvider";
import { MemberServiceProvider } from "./services/members/MemberServiceProvider";
import NewMemberCustomForm from "./components/membership/NewMemberCustomForm";
import { PaymentServiceProvider } from "./services/payments/PaymentServiceProvider";
import { HealthServiceProvider } from "./services/members/health/HealthServiceProvider";
import HealthForm from "./components/membership/health/HealthForm";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import Auth0ProviderWithConfig from "./components/login/Auth0ProviderWithConfig";
import DirectDebitManagement from "./components/directdebit/DirectDebitManagement";
import DirectDebitRunManager from "./components/directdebit/DirectDebitRunManager";
import MemberProfileScreen from "./components/membership/MemberProfileScreen";
import { FacilityBookingSchedule } from "./components/bookings/facility/FacilityBookingSchedule";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { ClientProvider } from "./common/ClientProvider";
import { ClientSelector } from "./common/ClientSelector";
import HomePage from "./HomePage";

const { Header, Sider, Content } = Layout;

const HealthFormWrapper: React.FC = () => {
  const { membershipId } = useParams<{ membershipId: string }>();

  if (!membershipId) {
    return <Navigate to="/error" replace />;
  }

  return <HealthForm membershipId={membershipId} />;
};

const LayoutWithRouter: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const { logout } = useAuth0();
  const screens = useBreakpoint();
  const isMobile = !screens.lg;

  const onCollapse = (collapsed: boolean) => {
    setCollapsed(collapsed);
  };

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const menuItems = [
    {
      key: "/home",
      icon: <HomeOutlined />,
      label: <Link to="/home">Home</Link>,
    },
    {
      key: "/bookings/facilities",
      icon: <CalendarOutlined />,
      label: <Link to="/bookings/facilities">Bookings</Link>,
    },
    {
      key: "/newmember",
      icon: <UserAddOutlined />,
      label: <Link to="/newmember">New Member</Link>,
    },
    {
      key: "members",
      icon: <UserAddOutlined />,
      label: "Members",
      children: [
        {
          key: "/profiles",
          icon: <UserAddOutlined />,
          label: <Link to="/profiles">Manage Members</Link>,
        },
      ],
    },
    {
      key: "directdebits",
      icon: <MoneyCollectFilled />,
      label: "Direct Debit",
      children: [
        {
          key: "/directdebit",
          icon: <MoneyCollectFilled />,
          label: <Link to="/directdebit">Member Direct Debits</Link>,
        },
        {
          key: "/directdebit/runs",
          icon: <MoneyCollectFilled />,
          label: <Link to="/directdebit/runs">Direct Debit Runs</Link>,
        },
      ],
    },
    { key: "logout", label: "Logout", onClick: handleLogout },
  ];

  const Logo: React.FC<{ collapsed?: boolean }> = ({ collapsed }) => {
    const screens = useBreakpoint();
    const isMobile = !screens.lg;

    return (
      <div
        style={{
          padding: isMobile ? "8px 0" : "16px",
          textAlign: "center",
          transition: "all 0.2s",
          height: isMobile ? "64px" : "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src="/ClubLogo.jpg"
          alt="Club Logo"
          style={{
            height: isMobile ? "48px" : collapsed ? "32px" : "64px",
            width: "auto",
            objectFit: "contain",
            maxWidth: "100%",
            transition: "all 0.2s",
          }}
        />
      </div>
    );
  };

  const MobileMenu: React.FC = () => {
    const dropdownMenu = (
      <Menu items={menuItems} selectedKeys={[location.pathname]} />
    );

    return (
      <Dropdown
        overlay={dropdownMenu}
        trigger={["click"]}
        overlayStyle={{
          maxHeight: "80vh",
          overflow: "auto",
        }}
      >
        <Button
          type="text"
          icon={<MenuOutlined />}
          style={{
            color: "white",
            fontSize: "20px",
            padding: "8px 12px",
          }}
        />
      </Dropdown>
    );
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      {/* Desktop Sider - Hidden on mobile */}
      {!isMobile && (
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={setCollapsed}
          style={{ background: "#00005a" }}
          width={250}
        >
          <Logo collapsed={collapsed} />
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[location.pathname]}
            style={{
              background: "#00005a",
              borderRight: 0,
              color: "white",
            }}
            items={menuItems}
          />
        </Sider>
      )}

      <Layout>
        <Header
          style={{
            padding: "0 16px",
            background: "#00005a",
            display: "flex",
            alignItems: "center",
            height: "64px",
            position: "sticky",
            top: 0,
            zIndex: 1000,
          }}
        >
          {isMobile ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                height: "64px",
              }}
            >
              <MobileMenu />
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  overflow: "hidden",
                }}
              >
                <Logo />
              </div>
            </div>
          ) : (
            // Add this block for desktop view
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div /> {/* Empty div for spacing */}
              <ClientSelector />
              <div style={{ paddingRight: "16px" }} />
            </div>
          )}
        </Header>

        <Content
          style={{
            margin: isMobile ? "16px 8px" : "24px 16px",
            padding: isMobile ? 16 : 24,
            minHeight: 280,
          }}
        >
          <BookingServiceProvider>
            <MemberServiceProvider>
              <PaymentServiceProvider>
                <HealthServiceProvider>
                  <Routes>
                    <Route path="/home" element={<HomePage />} />
                    <Route
                      path="/bookings/facilities"
                      element={<FacilityBookingSchedule />}
                    />
                    <Route path="/profiles" element={<MemberProfileScreen />} />
                    <Route
                      path="/newmember"
                      element={<NewMemberCustomForm />}
                    />
                    <Route
                      path="/members/healthform/:membershipId"
                      element={<HealthFormWrapper />}
                    />
                    <Route
                      path="/directdebit"
                      element={<DirectDebitManagement />}
                    />
                    <Route
                      path="/directdebit/runs"
                      element={<DirectDebitRunManager />}
                    />
                  </Routes>
                </HealthServiceProvider>
              </PaymentServiceProvider>
            </MemberServiceProvider>
          </BookingServiceProvider>
        </Content>
      </Layout>
    </Layout>
  );
};

const ProtectedLayout = withAuthenticationRequired(LayoutWithRouter);

const Auth0ErrorHandler: React.FC = () => {
  const { error } = useAuth0();

  if (error) {
    return (
      <div>
        <h1>An error occurred</h1>
        <p>{error.message}</p>
      </div>
    );
  }

  return null;
};

const styles = `
        .ant-menu-sub.ant-menu-inline {
            background: #161B38 !important;
        }
        .ant-menu-item-selected {
            background-color: #2A305C !important;
        }
     `;

const App: React.FC = () => {
  return (
    <>
      <style>{styles}</style>
          <Auth0ProviderWithConfig>
        <ClientProvider>
          <Router>
            <Auth0ErrorHandler />
            <ProtectedLayout />
          </Router>
        </ClientProvider>
      </Auth0ProviderWithConfig>
    </>
  );
};

export default App;
